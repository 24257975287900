import React from 'react';
import Layout from '../components/layout';
import DisclosureTemplate from '../templates/disclosure-template';
import SEO from '../components/seo';
import { graphql } from 'gatsby';

export const query = graphql`
	{
		allMarkdownRemark(filter: { frontmatter: { slug: { eq: "privacy" } } }) {
			nodes {
				rawMarkdownBody
				frontmatter {
					mast {
						title
						image
					}
				}
			}
		}
	}
`;

export default ({ data }) => {
	const content = data.allMarkdownRemark.nodes[0];
	return (
		<Layout page="Disclosures">
			<SEO title="Disclosures" />
			<DisclosureTemplate
				mast={content.frontmatter.mast}
				content={content.rawMarkdownBody}
			/>
		</Layout>
	);
};
