import React from 'react';
import Masthead from '../components/masthead';
import styled from 'styled-components';
import mdToHtml from '../util/mdToHtml';

const StyledContent = styled.div`
	padding: 3rem ${(p) => p.theme.gutter};

	h2 {
		padding-bottom: 2rem;
		margin: 1rem 0 0;
		color: ${(p) => p.theme.color.dark};
		font-weight: 700;
		position: relative;
		&::after {
			content: '';
			width: 50px;
			height: 3px;
			position: absolute;
			bottom: 0.8rem;
			left: 0;
			background-color: ${(p) => p.theme.color.orange};
		}
	}
	p {
		margin-bottom: 1rem;
	}
	ul {
		margin-bottom: 1.5rem;
		li {
			margin-bottom: 0.5rem;
			margin-left: 1.5rem;
			position: relative;
			&::before {
				content: '';
				background-color: ${(p) => p.theme.color.orange};
				height: 7px;
				width: 7px;
				border-radius: 7px;
				position: absolute;
				left: -1rem;
				top: 8px;
			}
		}
	}

	.subtext {
		font-size: 0.8rem;
		margin-top: 1rem;
	}
	@media all and (min-width: 1200px) {
		padding: 4rem ${(p) => p.theme.gutterWide};
	}
	@media all and (min-width: 1600px) {
		padding: 4rem ${(p) => p.theme.gutterXWide};
		h2 {
			font-size: 2rem;
			line-height: 2.5rem;
		}
		li {
			font-size: 1.25rem;
			line-height: 1.8rem;
		}
		p {
			line-height: 1.9rem;
		}
		.subtext {
			font-size: 1rem;
		}
	}

	/* Privacy Policy Page style */
	h3 {
		font-weight: 700;
		font-style: italic;
		margin-bottom: 0.5rem;
	}
	a {
		color: ${(p) => p.theme.color.dark};
		position: relative;
		&.underline-link::after {
			content: '';
			background-color: ${(p) => p.theme.color.dark};
			transition: 0.3s;
			width: 100%;
			height: 1px;
			position: absolute;
			bottom: 0;
			right: 0;
			opacity: 1;
		}
		&.underline-link:hover::after {
			opacity: 0;
		}
	}

	@media all and (max-width: 500px) {
		padding: 2rem ${(p) => p.theme.gutterMobile};
	}
`;

export default ({ mast, content }) => (
	<>
		<Masthead image={mast.image} title={mast.title} page="disclosures" />
		<StyledContent>
			<div
				dangerouslySetInnerHTML={{
					__html: mdToHtml(content),
				}}
			/>
		</StyledContent>
	</>
);
